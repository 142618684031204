// Import our variables and theme
@import './variables';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

html {
    height: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    height: 100%;
    overflow-y: scroll;
}

#root {
    min-height: 100%;
    background-color: #fff;
}

#frame-container {
    padding-left: 0;
    background-color: rgb(240, 242, 246);
    transform-origin: left;
}

#frame-container.collapsed {
    overflow: hidden;
}

.collapse-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 10; /* Ensure it stays on top */
    width: 30px; /* Adjust the width as needed */
    height: 30px; /* Adjust the height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

#page-content-wrapper {
    padding: 20px 28px;
    height: 100%;
}

.main-content-row {
    min-height: 100vh;
}

.sidebar {
    height: 100%;
    width: 100%;
}

.navbar-text {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
}

.nav-link.active {
    font-weight: bold;
}

.bg-img {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.login-modal {
    padding: 20px;
    margin: 15px;
    width: 100%;
    max-width: 500px !important;
}

.breadcrumb {
    padding-left: 8px;
    a {
        text-decoration: none;
    }
}

.custom-toggle-icon {
display: inline-block;
width: 20px; 
height: 3px;
background-color: #000; 
margin-top: 5px; 
}

.dropdown-toggle::after {
display: none; /* Hide the default arrow */
}

.form-label {
    margin-bottom: 10px;
    display: inline-block;
}

.header-container {
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 10px;
    border-bottom: 2px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-title {
    font-size: 2rem;
}

.header-domain {
    font-size: 1rem;
    color: #666;
}

.custom-tab {
    font-size: 1.5rem; 
    color: black; 
}

.custom-tab.active {
    font-weight: bold;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
    display: block !important;
    flex-basis: auto;
    }
}

.modal-halfscreen {
    width: 50%;
    max-width: 50%;
    margin: auto;
}

.modal-content-halfscreen {
height: 100%;
}

.table-border {
    border: 1px solid map-get($theme-colors, "watermark"); /* Green border */
    border-radius: 8px; /* Rounded corners */
    padding: 16px; /* Padding inside the border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Position the collapse button in the top-right corner */
.collapse-toggle-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

/* Flexbox growth constraints */
.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-grow-1 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0; /* Allows flexible use of space without infinite growth */
}

.overflow-hidden {
    overflow: hidden; /* Prevents overflow on zoom */
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}
